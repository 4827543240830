<template>
  <div class="body">
    <nav class="navbar navbar-expand-lg bgColor">
        <div class="container">
            <a class="navbar-brand" href="/">
                <img width="70" height="70" src="../../assets/caravan_quiz/caravan_logo.png" alt="">
                <div class="logo_text">
                    <p>AfCFTA</p>
                    <p>CARAVAN</p>
                </div>
            </a>
            <button class="navbar-toggler"
                style="border:none; color:#fff; outline:none; background:transparent; font-size:24px"
                type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-bars fa-2x" style="font-size:24px" ></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a :class="[active == 'home' ? '_active' : '']" @click.prevent="goto('home')" class="nav-link"  aria-current="page" href="#">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"  @click.prevent="goto('announced')" :class="[active == 'announced' ? '_active' : '']" href="#">Announcements</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click.prevent="goto('qoutes')"  :class="[active == 'qoutes' ? '_active' : '']" href="#">Quotes</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click.prevent="goto('jurors')"  :class="[active == 'jurors' ? '_active' : '']" href="#">Jurors</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click.prevent="goto('gala')"  :class="[active == 'gala' ? '_active' : '']" href="#">Gala</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click.prevent="goto('partners')"  :class="[active == 'partners' ? '_active' : '']" href="#">Partners</a>
                    </li>
                     <li class="nav-item">
                        <router-link class="nav-link" to="/laureates">Laureates</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <div class='container desktop' id="__desktop" ref="home">
        <div class='row'>
            <div class='col-md-4'>
            </div>
            <div class='col-md-6'>
                <div class='-subject-'>
                    AfCFTA Caravan Prize
                </div>
            </div>
            <div class='col-md-2'>
            </div>
        </div>
        <div class='row'>
            <div class='col-md-4 -heading-' ref="announced">
                <div class='-heading-margining-'>
                    <table>
                        <tr>
                            <p>Jury Announced</p>
                        </tr>
                        <tr><button class='-bar-'></button></tr>
                    </table>
                    <!--<hr>-->
                </div>
            </div>
            <div class='col-md-6 -body-'>
                <div class='block-1'>
                    <p>
                      The AfCFTA Secretariat and its partners on the 4D, ProPer and Caravan Initiatives, AfroChampions and the Arab Bank for Economic Development (BADEA), have announced the jury members for the AfCFTA Caravan Prize.
                    <p>
                      The AfCFTA Caravan Prize celebrates Africa's SMEs, startups, social enterprises and innovator entities keen on harnessing the benefits that will be created by AfCFTA to expand continentally and flourish at the grassroots. Growing out of the earlier AfCFTA Vision and Caravan Africa competitive grants programmes, the Prize seeks to discover transformative ideas to expedite AfCFTA implementation. 
                    </p>
                    
                    <p>&nbsp;</p>
                    <p>
                         A key criterion for selection as an AfCFTA Caravan Prize laureate is for an organisation and its founders to conceive and implement models, concepts, plans, products and/or services that, at scale, can contribute to the objectives of AfCFTA and the broader Agenda 2063 of the African Union, of which AfCFTA is a flagship.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        The Jury members come from a broad range of backgrounds and reflect their institutions’ strong commitment to AfCFTA, SMEs, startup innovation and inclusive growth. They now have the difficult task of picking the AfCFTA Caravan Prize semi-finalists, finalists and eventual winners. 

                    </p>
                    

                    <div class="cotent">
                      <div class='-heading-'>
                          <div class='-heading-margining- pt-5'>
                              <table>
                                  <tr>
                                      <p>About the AfCFTA Caravan Prize</p>
                                  </tr>
                                  <tr><button class='-bar-'></button></tr>
                              </table>
                              <!--<hr>-->
                          </div>
                      </div>
                      <p>&nbsp;</p>
                      <p>
                        The Prize is a component of the broader Caravan Initiative, which itself is a flagship of the AU-led 4D Initiative. The Caravan platform aims to provide extensive soft infrastructure to boost the capacity of SMEs and startups in Africa to expand beyond their home country. It is at the base of the ProPer Network, a supply chain quality assurance program created to ensure that the “Rules of Origin" system at the heart of AfCFTA works for both governments and businesses. Caravan also powers the “jobs in green tech" program, the Digital Green Corridor, designed to promote a trade & investment corridor for Africa's clean tech entrepreneurs to benefit from AfCFTA and the ongoing global green shift simultaneously. 
                      </p>
                      <p>&nbsp;</p>
                      <div class='the___winners___'>
                      <p style='color:rgb(128, 99, 4)'>
                      The winners of the AfCFTA Caravan Prize will be announced during a gala dinner at the Arab Africa Trade Forum on the 3rd of November 2021. Members of the public are warmly invited to join the AfCFTA Laureates online at <a href='http://www.bit.ly/AfCFTACaravan'>www.bit.ly/AfCFTACaravan</a> to celebrate African entrepreneurial energy at 9:00 pm (GMT+2).
                      </p>
                      </div>
                    </div>
                     <div class="cotent">
                      <div class='-heading-'>
                          <div class='-heading-margining- pt-5'>
                              <table>
                                  <tr>
                                      <p>About the AfCFTA</p>
                                  </tr>
                                  <tr><button class='-bar-'></button></tr>
                              </table>
                              <!--<hr>-->
                          </div>
                      </div>
                      <p>&nbsp;</p>
                      <p>
                        African Continental Free Trade Area (AfCFTA) is a flagship project of Agenda 2063 of the African Union — Africa’s own development vision. It was approved by the 18th ordinary Session of the Assembly of Heads of State and Government, held in Addis Ababa, Ethiopia in January 2012 which adopted the decision to establish a Continental Free Trade Area. This initiative whose immediate implementation would provide quick wins, impact on socio-economic development and enhance confidence and the commitment of Africans as the owners and drivers of Agenda 2063.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        The AfCFTA aims at accelerating intra-African trade and boosting Africa’s trading position in the global market by strengthening Africa’s common voice and policy space in global trade negotiations. The AfCFTA Agreement was signed on 21st March 2018 in Kigali, Rwanda, came into force on 30 May 2019. The scope of the AfCFTA Agreement includes the Protocol on Trade in Goods, the Protocol on Trade in Services, and the Protocol on Rules and Procedures on the Settlement of Disputes, the Protocol on Investment, the Protocol on Intellectual Property Rights, the Protocol on Competition, the Protocol on Digital Trade, and the Protocol on Women and Youth in Trade.
                      </p>
                    </div>
                </div>
            </div>
            <div class='col-md-2'>
            </div>
        </div>



        <!-- JURY start -->

      <div class='row block-space' style='border:0px solid red'>
                    <div class='col-md-4 -heading-' ref="jurors">
                        <div class='-heading-margining-'>
                          <table><tr><p>Caravan Prize Judges</p></tr><tr><button class='-bar-'></button></tr></table>
        
        <!--<hr>-->
        <div class='judges'>
          <!--HERE (b)-->
        <p  data-target="#carouselExampleIndicators" data-slide-to="0" class="active side_bar">Tshepelayi Kabata
        </p>
        
        <p data-target="#carouselExampleIndicators" data-slide-to="1" class="side_bar">Grace Khoza
        </p>
        <p data-target="#carouselExampleIndicators" data-slide-to="2" class="side_bar">Yusuf Daya
        </p>
        <p data-target="#carouselExampleIndicators" data-slide-to="3" class="side_bar">Memory Dube
        </p>
        <p data-target="#carouselExampleIndicators" data-slide-to="4" class="side_bar">Philip Myburgh
        </p>
        <p data-target="#carouselExampleIndicators" data-slide-to="5" class="side_bar">Joy Kategekwa
        </p>
        <!--HERE (e)-->
        </div>
        </div>
                    </div>
                    <div class='col-md-6 -body- ' style='border:0px dotted red; ' >

                      <!-- <p>Thursday 4th November, 2021. Thursday 4th November, 2021. Thursday 4th November, 2021</p> -->
                      <div class='-judges-flip- '>
                        

          <!--HERE (b)-->

                        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-touch="false" data-interval="false">
                          <ol class="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
                          </ol>
                          <!-- Judge One -->
                          <div class="carousel-inner">
                            <div class="carousel-item active">
                              <table class='table'>
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'><div class='judge judge_one ' style="margin:auto"></div></td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Tshepelayi Kabata
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                        </td>
                                                      </tr>
                            
                                                      <!--<tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Technical Advisor, 
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Office of the Director General, 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>-->
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>BADEA 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                            
                                                    </table>
                            </div>
                          
                          <!-- Judge Two -->
                            <div class="carousel-item">
                              <table class='table'>
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'><div class='judge judge_two ' style="margin:auto"></div></td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Grace Khoza
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                        </td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>AfCFTA Secretariat 
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <!-- <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Office of the Director General, 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>BADEA. 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                             -->
                            
                                                    </table>
                            </div>
                          
                          <!-- Judge Three -->
                            <div class="carousel-item">
                        <table class='table'>
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'><div class='judge judge_three ' style="margin:auto"></div></td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p> Yusuf Daya
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                        </td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Afreximbank 
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <!-- <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Office of the Director General, 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>BADEA. 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr> -->
                            
                            
                                                    </table>
                        
                            </div>
                          
                          <!-- Judge Four -->
                            <div class="carousel-item">
                         <table class='table'>
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'><div class='judge judge_four ' style="margin:auto"></div></td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p> Memory Dube
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                        </td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Africa Development Bank 
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <!-- <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Office of the Director General, 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>BADEA. 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr> -->
                            
                            
                                                    </table>
                            </div>
                          
                          <!-- Judge Five -->
                            <div class="carousel-item">
                              <table class='table'>
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'><div class='judge judge_five ' style="margin:auto"></div></td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p> Philip Myburgh
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                        </td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Standard Bank Group 
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <!-- <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Office of the Director General, 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>BADEA. 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr> -->
                            
                            
                                                    </table>
                            </div>
                          
                          <!-- Judge Six -->
                            <div class="carousel-item">
                              <table class='table'>
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'><div class='judge judge_six ' style="margin:auto"></div></td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p> Joy Kategekwa
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                        </td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <!-- <p>Standard Bank Group 
                                                        </p> -->
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <!-- <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>Office of the Director General, 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr>
                            
                                                      <tr style="width:100%; border:0px solid orange">
                            
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'>
                                                          
                                                                                      
                                                        </td>
                            
                                                        <td style='width:50%;border:0px solid orange;text-align: center;'>
                                                        <p>BADEA. 
                             
                                                        </p>
                                                        </td>
                                                        
                                                        <td style='width:25%;border:0px solid orange;text-align: center;'></td>
                                                      </tr> -->
                            
                            
                                                    </table>
                            </div>
                          
                          </div>
                          <button class="carousel-control-prev" type="button" data-target="#carouselExampleIndicators" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                          </button>
                          <button class="carousel-control-next" type="button" data-target="#carouselExampleIndicators" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                          </button>
                        </div>


                        <!--HERE (e)-->
                        
                      </div>
                      <!-- <div id='caro__btn' >
                        <a class="left carousel-control" href="#myCarousel" data-slide="prev">
                          <button class=''>
                            <img src='assets/caret-left.svg'>
                          </button>
                        </a>
                        
                        <a class="right carousel-control" href="#myCarousel" data-slide="next">
                          <button class=''>
                            <img src='assets/caret-right.svg'>
                          </button>
                        </a>
                      </div> -->
                       
                        </div>
                        <div class='col-md-2'>
                          
                                      </div>
                        </div>
             

        <!-- JURY end -->


        <!-- QUOTES  -->
        <div class="row __main__qoute" >
          <div class='col-md-4 -heading-' ref="qoutes">
                <div class='-heading-margining-'>
                    <table>
                        <tr>
                            <p>Quotes</p>
                        </tr>
                        <tr><button class='-bar-'></button></tr>
                    </table>
                </div>
            </div>
            <div class="col-md-6">
              <div class="row __qoute_section">
                <div class="col-2">
                  <button @click="prev" :class="[prevActive ? '__nav__btn_active' : '']" >
                  <i class="fas fa-angle-left"></i>
                </button>
                </div>
                <div class="col-8 __qoute_text">
                  <p class="__quote">
                    {{ `"${qouteData[index].body}"`}}
                  </p>
                  <p class="__author">
                    {{ qouteData[index].author }}
                  </p>
                </div>
                <div class="col-2">
                  <button @click="next" :class="[nextActive ? '__nav__btn_active' : '']">
                    <i class="fas fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-2">

            </div>
        </div>
    <!-- caravan prize -->
        <div class='row block-space __gala' ref="gala">
            <div class='col-md-4 -heading-'>
                <div class='-heading-margining-'>
                    <table>
                        <tr>
                            <p>Gala</p>
                        </tr>
                        <tr><button class='-bar-'></button></tr>
                    </table>
                    <div class='gala-date'>
                        <p>Wednesday 3rd November, 2021</p>
                        <p>Arab Africa Trade Forum</p>
                    </div>
                </div>
            </div>
            <div class='col-md-6 -body-'>
                <div class='block-3'>
                    <div class='__4'></div>
                </div>
            </div>
            <div class='col-md-2'>
            </div>
        </div>
    </div>
    <!--CONTAINER ENDS-->
    <div class="circle-divider"></div>
      <div class='continer'>
        <div class='row block-space -partners-' ref="partners">
            <div class='col-md-4 -heading-'>
              <div class='-heading-margining-'>
                  <table>
                    <tr>
                        <p class='-p-'>Partners</p>
                    </tr>
                    <tr><button class='-bar-'></button></tr>
                  </table>
              </div>
            </div>
            <div class='col-md-6 -body-' style='border:0px solid red'>
            </div>
            <div class='col-md-2'>
            </div>
        </div>
      </div>
      <div class='block-4 containr' style='background-color: white;' >
        <div class='big-screens'>
            <table class='table  ___partners___'>
              <tr>
                  <td class='__empty'> </td>
                  <td>
                    <div class='-partners-emblem-afcta-'>
                    </div>
                  </td>
                  <td>
                    <div class='-partners-emblem-au-'>
                    </div>
                  </td>
                  <td class='__empty'> </td>
              </tr>
              <tr>
                  <td class='__empty'> </td>
                  <td>
                    <div class='-partners-emblem-sankoree-'>
                    </div>
                  </td>
                  <td>
                    <div class='-partners-emblem-champs-'>
                    </div>
                  </td>
                  <td class='__empty'> </td>
              </tr>
              <tr>
                  <td class='__empty'> </td>
                  <td>
                    <div class='-partners-emblem-badea-'>
                    </div>
                  </td>
                  <td>
                    <div class='-partners-emblem-afreximbank-'>
                    </div>
                  </td>
                  <td class='__empty'> </td>
              </tr>
            </table>
        </div>
        <table class='table rights' style='color:black; position:relative;'>
            <tr>
              <td style='width:100%'>
                  <p> <small> &copy; {{ getFullYear }} AfCFTA Caravan Prize. All rights reserved.  </small>         </p>
              </td>
            </tr>
        </table>
    </div>
</div>
</template>

<script>
import $ from 'jquery'
$(window).scroll(function() {
    $('nav').toggleClass('scrolled',$(this).scrollTop() > 0)
})
export default {
  name:'jury-announcement',
  data() {
    return {
      active: 'home',
      index: 0,
      nextActive: true,
      prevActive: false,
      qouteData:[
        {
          id:1,
          body:'If there is one hope that animates everything we do as a Secretariat, it is that AfCFTA must become the most inclusive and most digital Free Trade Area & Process in the World. By focusing on SMEs, digital innovation, gender & equitable transformation, the AfCFTA Caravan Prize will serve as a continental beacon for Agenda 2063. I celebrate the Prize Laureates for sharing our vision, and thank the Jury and Partners for stepping up to the challenge.',
          author:'~ H.E. Wamkele Mene -  Secretary General, AfCFTA Secretariat '
        },
        {
          id:2,
          body:"Africa cannot take advantage of AfCFTA to industrialise and eradicate poverty until three things happen: SMEs absorb tech to boost productivity, wealth from productivity growth is equitably shared, and the scale of growth becomes regional. The AfCFTA Caravan Laureates exemplify how we can achieve all 3 goals, hence our enthusiastic support as BADEA for this initiative.",
          author:'~ Dr. Sidi Ould TAH - Director General, Arab Bank for Economic Development in Africa (BADEA) '
        },
        {
          id:3,
          body:"Of all the flagships meant to take Africa to Agenda 2063, AfCFTA is today the most critical. But AfCFTA itself must have flagships. I think that there is no better candidate than the Caravan Initiative, of which the AfCFTA Caravan Prize is a major component. Caravan's emphasis on youth, gender, equity & technology provides all the pillars for erecting a vision of AfCFTA that all sections of African society can rally behind. It is as much a banner as a flagship.",
          author: '~ H.E. Mahamadou Issoufou - Former President of Niger & African Union AfCFTA Champion'
        },
      ]
    }
  },
  computed: {
    getFullYear() {
      return new Date().getFullYear()
    }
  },
  methods: {
    next() {
      if(this.index > 2) {
        this.index = 0;
        return;
      }
      this.index = this.index + 1
      this.nextActive = true
      this.prevActive = false
    },
    prev() {
      if(this.index < 0) {
         this.index = 0
         return;
      }
      this.index = this.index - 1
      this.prevActive = true
      this.nextActive = false
    },
    goto(refName) {
      this.active = refName
      var element = this.$refs[refName];
      var top = element.offsetTop;
      console.log('top', top)
      window.scrollTo(0, top);
    }
  },
  mounted() {
    
  }
}
</script>

<style lang="scss" scoped>
.bgColor.scrolled{
    background-color: #000;
}
nav{
  width: 100%;
  top:0;
  z-index: 1000;
  position: fixed;
}
@font-face {
    font-family:Lust;
    src: url(../../assets/caravan_quiz/assets/DMSerifDisplay-Regular.ttf);
}
@font-face {
    font-family:Eina-bold;
    src: url(../../assets/caravan_quiz/assets/Eina02-Bold.ttf);
}
@font-face {
    font-family:Inter;
    src: url(../../assets/caravan_quiz/assets/Inter-Regular.ttf);
}
@font-face {
    font-family:Circular;
    src: url(../../assets/caravan_quiz/assets/CircularStd-Book.otf);
}
.body{
    padding: 0;
    margin: 0;
    background-color: rgb(10, 6, 5);
    background-size: 48px 48px;
    background-image: radial-gradient(
        transparent 20px,
        #000 20px,
        #000 24px,
        transparent 24px
    ),
    radial-gradient(
        transparent 20px,
        #000 20px,
        #000 24px,
        transparent 24px
    );
    background-position: 0 0 , 24px 24px;
}
._active{
  color:rgb(128, 99, 4)!important;
}
.-subject-{font-family: Lust; font-size: 84px; width: 60%; color:white; margin-bottom: 1.4em; line-height: 100%; }
h1{color:white}
header{position:fixed; top:0px; width: 100%; height:45;background:transparent; z-index:5}
 .logo{background-image: url('../../assets/caravan_quiz/assets/AfCFTA-CARAVAN-4.png'); background-repeat:no-repeat; background-position: center;
 background-color: transparent; height: 68px;
background-size:cover;
width: 102px;position:relative;top: 15px;}
.-partners-emblem-afcta-, .-partners-emblem-au-, .-partners-emblem-sankoree-, .-partners-emblem-champs-,.-partners-emblem-badea-, .-partners-emblem-afreximbank-{background-repeat:no-repeat; background-position: center;
    background-color: transparent; 
    background-size:cover;
    position:relative;}
    .-partners-emblem-afcta-{ background-image: url('../../assets/caravan_quiz/assets/new_AfCFTA-logo_1.32a017a8.png');height: 90px;width: 90px; }
    .-partners-emblem-au-{ background-image: url('../../assets/caravan_quiz/assets/auweblogo-en.d2ac7604.png'); height: 55px;width: 158px;}
    .-partners-emblem-sankoree-{ background-image: url('../../assets/caravan_quiz/assets/7 2B.05702b7b.png'); height: 100px;width: 102px;}
    .-partners-emblem-champs-{ background-image: url('../../assets/caravan_quiz/assets/afrochamps.jpeg'); height: 60px;width: 170px; }
    .-partners-emblem-badea-{ background-image: url('../../assets/caravan_quiz/assets/badea.png'); height: 90px;width: 90px; }
    .-partners-emblem-afreximbank-{ background-image: url('../../assets/caravan_quiz/rsz_11afrexim.jpg'); height: 90px;width: 90px; }
.-heading-margining- {width:70%; }
.judges, .gala-date {text-align: left;font-size: 14px; font-family: Eina-bold; position:relative; }
/* .judge-highlight{color:rgb(128, 99, 4); font-size: 16px;} */
.-bar-{height:7px;width:50px;background-color: rgb(128, 99, 4);border:none;position:relative;top:-25px}
.-partners- .-bar-{background-color:black;}
.container{top:20px}
.-body-,.block-4 p{color:white; font-family:Inter;
    font-size: 16px; text-align: left;}
.block-space{padding-top: 7em;}
.-heading- {font-family: Lust; font-size: 29px;color:white;text-align: left; }
.-partners-{background-color: white;position:relative;width: 100%
    ;margin-right: 0px;margin-left: 0px;z-index: 3;color:rgb(95, 91, 91);height: auto; padding: 40px;}
    .block-4 p{color:black; font-family:Inter;
        font-size: 17px; }
        .-heading-margining- .-p-{color: black;}
    /* $size:45px; */
    .circle-divider{background-color: rgb(255, 255, 255);width:100%; height:70px; border-radius: 50%;position: relative;top:2.30em;z-index: 2;}
    .raph{font-family: Eina-bold;}
.raph span{color:rgb(128, 99, 4);font-family: Eina-bold;}
.-judges-flip-{background-color: #262626;height:350px;width:100%;border-radius: 12px; }
.-judges-flip- button{border-radius: 100px;width:40px;height:40px;border:2px solid black;background-color: #000; top:7em}  
#-caret-2{float:right} 
.judge{background-repeat:no-repeat; background-position: center;
    background-color: transparent; 
    background-size:cover;
    position:relative; border-radius:100px ; margin-top:20px}
    .judge_one{background-image: url('../../assets/caravan_quiz/assets/tshepe.jpg');height: 140px;width: 140px; }
    .flex{align-content: space-between;  }
    .-just-{display:flexbox;justify-content: center;}
    ._4{font-size:270px; font-weight:600; color:#262626; font-family: Arial Black; line-height: 80%;}
    .November{color:seashell;height:200px;width:165px;border:1px solid #fff;font-size: 60px;word-break: break-all;font-family: Eina-bold;font-weight:600;line-height: 80%;position: relative;right:100px;}
    .__4{background-repeat:no-repeat; background-position: center;
        background-color: transparent; 
      /* background-size: cover; */
        position:relative;  background-image: url('../../assets/caravan_quiz/assets/4--.png');height: 235px;width:352px;border: 0px solid red; }
        .navbar{background:transparent;border:none;
          padding-top: 3em;
        }
        .navbar-expand-lg .navbar-toggle:hover, .navbar-default .navbar-toggle:focus{color:rgb(128, 99, 4)}
        .navbar-nav li a{
          color:#fff;
          font-size: 1.2em;
        }
        .navbar-nav li{
          padding-left: 1em;
        }
        .navbar-nav {
          margin-right: 17%;
        }
        .container__{
            margin:25px;
            width:350px;
            height: 200px;;
            border: 0px dotted red;
            }
            .child{
                width:50px;
                height:50px;
                background:red;
                position:absolute;
                top:50%;
                left:50%;
                margin: -25px 0 0 -25px;
            }
@media screen and (max-width: 768px) {
    #__desktop{
      padding-top: 8em;
    }
   .navbar{
          padding-top: 1.5em;
          padding-bottom: 1em;
        }
    .-subject-{
      font-size: 55px;
      padding-top:1em;
    }
    .collapse .navbar-collapse{
      background-color:#000;
      color:#fff;
    }
    .__footer{
      text-align: center;
    }
    ._logo_s{
      margin-bottom: 2em;
    }
}
.navbar-brand{
  display: flex;
}
.logo_text{
  margin-left:.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p{
    margin-bottom: -10px;
    color:#fff;
    font-size: .9em;
    font-family:Inter;
  }
}
.__footer{
  background-color: #fff;
  padding-top:3em;
  padding-bottom: 1em;
  font-family:Inter;
  font-size: 1em;
}
#caro__btn{position: relative; top:-10em }
.judge_two{background-image: url('../../assets/caravan_quiz/Grace Khoza.jpeg');height: 140px;width: 140px; }
.judge_three{background-image: url('../../assets/caravan_quiz/Yusuf Daya.jpeg');height: 140px;width: 140px; }
.judge_four{background-image: url('../../assets/caravan_quiz/Memory Dube.jpeg');height: 140px;width: 140px; }
.judge_five{background-image: url('../../assets/caravan_quiz/Philip Myburgh.jpeg');height: 140px;width: 140px; }
.judge_six{background-image: url('../../assets/caravan_quiz/Dr. Joy Kategekwa.jpg');height: 140px;width: 140px; }
.__qoute_section{
  width: 100%;
  font-family:Inter;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  .__qoute_text{
    padding:1em;
  }
  .__quote{
    color:rgb(128, 99, 4);
    text-align: center;
    font-style: italic;
    line-height: 2em;
  }
  .__author{
    color:#fff;
    padding-top:1em;
  }
  button{
    background-color: #262626;
    outline:none;
    border:none;
    font-size: 1.2em;
    font-family:Inter;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#fff;
  }
}
.__nav__btn_active {
  background-color: #fff!important;
  color:#000!important;
}
.__main__qoute{
  margin-top: 8em;
}
.__gala{
  margin-bottom:4em;
}
.__side_nav{
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }
  ul li {
    font-size: 14px;
    font-family:Inter;
    padding-bottom: 2em;
    button{
      background-color: transparent;
      border:none;
      outline:none;
      color:#fff;
      font-size: 14px;
    }
  }
}
.desktop{
  padding-top: 17em;
}
.-judges-flip- td p{color:white}
        .judges p:hover{color: rgb(128, 99, 4);}
        .carousel-control-next-icon, .carousel-control-prev-icon{width:15px; height:15px;color:black}
.___partners___ td{width:25%; border:0px solid red; }
        .___partners___ td div{margin:auto}
        .___partners___{width:100%;border:0px solid red}
                .rights td p{margin:auto; text-align: center;}
.side_bar {
  cursor: pointer;
}
.the___winners___{border:1px dotted rgb(128,99,4); padding:10px; border-right:none;border-left:none}
</style>
